import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import {omit, get} from 'lodash'
import Helmet from 'react-helmet'

class SEO extends React.Component {
  constructor(props) {
    super(props)
    const { site :  { siteMetadata : { title, description, seoTitle, siteUrl } } } = this.props.data
    const metaTitle = get(this.props, 'title', title)
    const metaDescription = get(this.props, 'description', description)
    const defaultMetaImage = get(this.props, 'data.ogDefaultImage.childImageSharp.fluid')
    const metaImage = get(this.props, 'image', defaultMetaImage) || defaultMetaImage
    const metaKeywords = get(this.props, 'keywords')
    this.seo = {
      htmlAttributes: {lang: 'en'},
      title: get(this.props, 'seoTitle', seoTitle),
      meta: [
        {
          name: 'title',
          content: metaTitle
        },
        {
          name: 'description',
          content: metaDescription
        },
        {
          property: 'og:title',
          content: metaTitle
        },
        {
          property: 'og:description',
          content: metaDescription
        }
      ]
    }


    if (metaKeywords) {
      this.seo.meta.push(
        {
          name: 'keywords',
          content: metaKeywords.join(',')
        }
      )
    }

    if (metaImage && metaImage.src) {
      let miSrc = metaImage.src
      if (miSrc.match(/^\/[^\/]+/)) { //Meaning it's a relative path to our files
        miSrc = `${siteUrl}${miSrc}`
      }
      let metaImageStructure = [
        {
          property: "og:image",
          content: miSrc
        }
      ]
      if (metaImage.width) {
        metaImageStructure.push({
          property: "og:image:width",
          content: metaImage.width
        })
      }
      if (metaImage.height) {
        metaImageStructure.push({
          property: "og:image:height",
          content: metaImage.height
        })
      }
      this.seo.meta = this.seo.meta.concat(metaImageStructure)
    }
  }
  render() {
    return (
      <Helmet { ...this.seo } >
        { this.props.structuredData && typeof JSON !== undefined  ? (
          <script type='application/ld+json'>
            { JSON.stringify(this.props.structuredData) }
          </script>
        ) : '' }
        { this.props.disallowIndexing === true ? (
          <meta name="robots" content="noindex" />
        ) : '' }
        { this.props.children }
      </Helmet>
    )
  }
}
export default (props) => {
  const data = useStaticQuery(graphql`
    query SeoQuery {
      site {
        siteMetadata {
          title,
          description,
          seoTitle,
          idGTM,
          siteUrl
        }
      }
      ogDefaultImage: file(relativePath: { eq: "og-default.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <SEO  {...props} data={data}/>
  )
};
