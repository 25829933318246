import React from 'react'
import Modal from 'react-bootstrap/Modal';

/**
 * Declares basic functionality for Modals
 * @extends React
 */
class BaseModal extends React.Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.state = { show: false };
  }

  closeModal() {
    this.setState({
      show: false
    })
  }
  
  showModal() {
    this.setState({
      show: true
    })
  }
}

export default BaseModal