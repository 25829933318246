import React from "react";
import styles from './sticky-bar.scss';
import {get} from "lodash";
import {graphql, useStaticQuery} from "gatsby";

class StickyBar extends React.Component {

  render() {
    const { data} = this.props
    const ctaUrl = `${get(data, 'site.siteMetadata.certapetInfo.url.ctaUrl')}`
    return (
      <div id="sticky-bar" className="container-fluid sticky-bar px-0">
        <div className="sticky">
          {/*<a href={`${ctaUrl}&utm_campaign=esacanadav2&utm_content=stickybar`} target="_blank">APPLY NOW FOR AN ESA LETTER</a>*/}
          <a href="./screening" target="_blank">APPLY NOW FOR AN ESA LETTER</a>
        </div>
      </div>
    )
  }
}
export default (props) => {
  const data = useStaticQuery(graphql`
    query StickyBar {
      site {
        siteMetadata {
          certapetInfo {
            url {
              ctaUrl
            }
          }
        }
      }
    }
  `)
  return (
    <StickyBar  {...props} data={data}/>
  )
};


