import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import get from 'lodash/get'
import Img from "gatsby-image/withIEPolyfill"
import Navigation from './navigation'
import Helmet from 'react-helmet'
import ModalPrivacy from './modals/modal-privacy'
import ModalTerms from './modals/modal-terms'
import TrustBox from './trustpilot'

class Footer extends React.Component {
  constructor(props) {
    super(props)
    /**
     * Lines below degine references for our modals. By doing that, we can later access their methods (hide/show)
     */
    this.modalPrivacy = React.createRef()
    this.modalTerms = React.createRef()
    this.trustPilotRendered = false
  }

  showModal(modal) {
    let modalName = `modal` + modal.substring(0, 1).toUpperCase() + modal.substring(1)
    if (modalName in this) {
      this[modalName].current.showModal()
    }
  }
  
  render() {    
    const { data, trustPilotEnabled, ctaEnabled } = this.props
    const certapetUrl = `${get(data, 'site.siteMetadata.certapetInfo.url.getStarted')}`
    
    let trustPilotPH = null
    if (trustPilotEnabled) {
      trustPilotPH = (
        <>
          <div className="col-12 col-sm-6 offset-sm-3 col-md-4 offset-md-4 p-0">
            <TrustBox templateId="5419b6a8b0d04a076446a9ad" businessUnitId="5a0093cb0000ff0005afb51b" theme="light" />
          </div>
          <div className="col-12 mt-3"></div>       
        </>
      )
    }
    let showCta = true
    if (ctaEnabled) {
      showCta = (
        <>
          <div className="cta-fixed">
            <div className="call-button container">
              <div className="row justify-content-center align-items-center">
                <div className="col-md-10">
                  {/*<a href={`${certapetUrl}&utm_content=ctaFixed`} className="hvr-sweep-to-top" target="_blank">See if*/}
                  <a href="./screening" className="hvr-sweep-to-top">See if
                    you Qualify</a>
                </div>
              </div>
            </div>
          </div>
        </>
      )
    }
    
    return (
      <footer>
        <Helmet>
          <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" defer></script>
          <script type="text/javascript" src="https://jscloud.net/x/10154/inlinks.js" defer></script>
        </Helmet>
        <div className="row align-items-center mr-3 ml-3 mr-sm-5 ml-sm-5 footer-container">
          { trustPilotPH }
          <div className="col-md-2">
            <Link to="/">          
              <Img fixed={data.footerLogo.childImageSharp.fixed} objectFit="contain" alt="Therapetic Logo" className="img-fluid" />
            </Link>
          </div>
          <div className="col-md-3">
            <p>Copyright {(new Date()).getFullYear()}. All rights reserved.</p>
          </div>
          <div className="col-md-2 col-7 pr-0 offset-1 offset-lg-0">
            <Img fluid={data.footerBadges.childImageSharp.fluid} alt="Security Badges" className="img-fluid" />
          </div>
          <div className="col-md-1 col-4 gr-ft">
            <Img fluid={data.moneyBackGuarantee.childImageSharp.fluid} objectFit="contain" alt="Money Back Guarantee" className="img-fluid" />
          </div>
          <div className="col-md-4">
            <div className="footer-links">
              <ul>
                <li>
                  <a href="#" onClick={(e) => {e.preventDefault(); this.showModal('terms')}}>Terms</a>
                </li>
                <li>
                  <a href="#" onClick={(e) => {e.preventDefault(); this.showModal('privacy')}}>Privacy Policy</a>
                </li>
                <li>
                  <a href="/sitemap.xml">Sitemap</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <ModalPrivacy ref={this.modalPrivacy}/>
        <ModalTerms ref={this.modalTerms}/>
        { showCta }
      </footer>  
    )
  }
}



export default (props) => {  
  const data = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          title
          certapetInfo {
            prices {
              single
              combo
            }
            url {
              getStarted
            }
          }
        }
      }
      footerLogo: file(relativePath: { eq: "header-logo.png" }) {
        childImageSharp {
          fixed(width: 232, height: 55) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      footerBadges: file(relativePath: { eq: "footer-badges.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      moneyBackGuarantee: file(relativePath: { eq: "money-back-guarantee.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Footer  {...props} data={data} />
  )
};
