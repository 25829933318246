import React from 'react'
import { Link } from 'gatsby'
import Img from "gatsby-image/withIEPolyfill"
import get from 'lodash/get'
import Dropdown from 'react-bootstrap/Dropdown';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import animate from '../scss/navigation.scss'
import { isExternalUrl } from "../../src/helpers.js"

class Navigation extends React.Component {
  render() {
    const { menu, logo } = this.props
    
    /**
     * Extracts attribute from list of potential attributes
     * @param  {ContentfulMenuItem} menuItem
     * @param  {string} attribute's name
     * @param  {string} defaultItemValue
     * @param  {boolean} appendDefault
     * @return {string}
     */
    const extractValueFromAttributes = (menuItem, attribute, defaultItemValue, appendDefault) => {
      let attributeValue = defaultItemValue || ``
      if (menuItem.extraAttributes) {
        let extraAttributes = JSON.parse(get(menuItem, 'extraAttributes.internal.content', `{}`))
        if (attribute in extraAttributes) {
          attributeValue = appendDefault ? `${attributeValue} ${extraAttributes[attribute]}` : extraAttributes[attribute]
        }
      }
      
      return attributeValue
    }
    
    /**
     * Generates markup for single items
     * @param  {ContentfulMenuItem} menuItem
     * @return {HTMLDomElement}
     */
    const singleItemRenderer = (menuItem) => {
      let className = extractValueFromAttributes(menuItem, `className`, `nav-item text-uppercase`, true)
      return (
        // Because Nav.Link component doesn render nested component li > a
        <li className="nav-item">
          {
            isExternalUrl(menuItem.url) 
              ? (<Nav.Link href={ menuItem.url } className={className} key={menuItem.id} as="a">{ menuItem.label }</Nav.Link>)
              : (<Link to={ menuItem.url } className={className} key={menuItem.id}>{ menuItem.label }</Link>)
          }          
        </li>
      )
    }
    
    /**
     * Generates markup for dropdown items
     * @param  {ContentfulMenuItem} menuItem 
     * @return {HTMLDomElement}
     */
    const dropdownItemRenderer = (menuItem) => {
      let className = extractValueFromAttributes(menuItem, `className`, `nav-item dropdown`, true)
      
      return (
        <NavDropdown title={menuItem.label} id="navbarDropdown" key={menuItem.id}>
          {menuItem.contentfulchildren.map((innerMenuItem, i) => {
            let className = extractValueFromAttributes(innerMenuItem, `className`, `dropdown-item`, false)
            if (isExternalUrl(innerMenuItem.url)) {
              return (<NavDropdown.Item className={className} href={innerMenuItem.url} key={innerMenuItem.id}>{innerMenuItem.label}</NavDropdown.Item>)
            } else {
              return (<Link to={ innerMenuItem.url } className={className} key={innerMenuItem.id}>{ innerMenuItem.label }</Link>)
            }
          })}
        </NavDropdown>
      )
    }
    const menuId = extractValueFromAttributes(menu, `id`, `main-menu-therapetic`)
    
    return (
      <>
      <Navbar expand="lg" id={ menuId }>
        <Navbar.Brand href="/" className="col-md-2 col-sm-6 col-6 logo pl-0">
          <Img fluid={logo.childImageSharp.fluid} alt="Therapetic Logo" className="img-fluid logo" />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse id="navbarNav">
          <Nav className="ml-auto" as="ul">
            {menu.menuItems.map((menuItem, i) => {
              // console.log(menuItem)
              const isSingle = !menuItem.contentfulchildren
      
              return isSingle ? singleItemRenderer(menuItem) : dropdownItemRenderer(menuItem);        
            })}            
            <li className="nav-item">
              <div class="cta-button d-sm-none">
                {/* Item below is not rendered as a React/Bootstrap item because we want to avoid the default classes here */}
                {/*<a href="https://www.certapet.com/get-started/?utm_source=therapetic.org&utm_medium=referral&utm_campaign=home&utm_content=cta1" className="hvr-sweep-to-right">GET STARTED</a>*/}
                <a href="/get-started" className="hvr-sweep-to-right">GET STARTED</a>
              </div>
            </li>
          </Nav>
        </Navbar.Collapse>
        <div className="col-sm-3 cta-button pr-0 d-none d-sm-block">
            {/*<a className="hvr-sweep-to-right" href="https://www.certapet.com/get-started/?utm_source=therapetic.org&utm_medium=referral&utm_campaign=home&utm_content=cta1">GET STARTED</a>*/}
            <a className="hvr-sweep-to-right" href="/get-started">GET STARTED</a>
        </div>
      </Navbar>
      </>
    )
  }
}

export default (props) => { 
  return (
    <Navigation  {...props} />
  )
};
