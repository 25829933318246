import React, { Component } from 'react';

class TrustBox extends Component {
  constructor(props) {
    super(props);
    this.trustBoxRef = React.createRef();
  }
  componentDidMount() {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(this.trustBoxRef.current, true);
    }
  }
  render() {
    return (
      <div
          ref={ this.trustBoxRef } 
          className="trustpilot-widget" 
          data-locale="en-US" 
          data-template-id={this.props.templateId}
          data-businessunit-id={this.props.businessUnitId}
          data-style-height="24px" data-style-width="100%" 
          data-theme={this.props.theme}>
        <a
          href="https://www.trustpilot.com/review/example.com"
          target="_blank"
          rel="noopener noreferrer">
          Trustpilot
        </a>
      </div>
    )
  }
}

export default TrustBox;