import React from "react";
import SectionMenu from "../section-menu/section-menu";
import { get } from 'lodash'
import {graphql, useStaticQuery} from "gatsby";

class Header extends React.Component {
  render() {
    const { hiddenHeader } = this.props
    const siteMeta = get(this, 'props.data.site.siteMetadata')

    return (
      <header className={`page-layout ${hiddenHeader?'':'mt-5'} py-md-3 py-0`}
              itemID={`#${siteMeta.publisher.name.replace(' ', '')}Org`}
              itemScope
              itemType="http://schema.org/Organization"
      >
        <SectionMenu hiddenHeader={hiddenHeader}/>
        <meta itemProp="name" content={siteMeta.publisher.name} />
        <meta itemProp="url" content={siteMeta.publisher.url} />
        <meta itemProp="areaServed" content={siteMeta.publisher.areaServed} />
        <meta itemProp="alternateName" content={siteMeta.publisher.alternateName} />
        <meta itemProp="foundingDate" content={siteMeta.publisher.foundingDate} />
        {siteMeta.publisher.sameAs.map((row) => {
          return (
            <meta itemProp="sameAs" content={row} key={row}/>
          )
        })}
        <meta itemProp="description" content={siteMeta.description} />
        <meta itemProp="telephone" content={siteMeta.publisher.phone} />
        <div
          itemProp="logo"
          itemScope
          itemType="https://schema.org/ImageObject"
        >
          <meta itemProp="url" content={siteMeta.publisher.logo} />
        </div>
        <div
          itemProp="address"
          itemScope
          itemType="https://schema.org/PostalAddress"
        >
          <meta
            itemProp="streetAddress"
            content={siteMeta.publisher.address.streetAddress}
          />
          <meta
            itemProp="addressLocality"
            content={siteMeta.publisher.address.addressLocality}
          />
          <meta
            itemProp="postalCode"
            content={siteMeta.publisher.address.zip}
          />
          <meta
            itemProp="addressCountry"
            content={siteMeta.publisher.address.country}
          />
        </div>
        <div itemProp="contactPoint" itemScope itemType="https://schema.org/contactPoint">
          <meta itemProp="contactType"
                content={siteMeta.publisher.contactPoint.contactType}
          />
          <meta itemProp="email"
                content={siteMeta.publisher.contactPoint.email}
          />
        </div>
        <div itemProp="founder" itemScope itemType="https://schema.org/Person">
          <meta itemProp="name"
                content={siteMeta.publisher.person.name}
          />
          <meta itemProp="gender"
                content={siteMeta.publisher.person.gender}
          />
          <meta itemProp="jobTitle"
                content={siteMeta.publisher.person.jobTitle}
          />
          {siteMeta.publisher.person.sameAs.map((row) => {
            return (
              <meta itemProp="sameAs" content={row} key={row}/>
            )
          })}
        </div>
        <div itemProp="aggregateRating" itemScope itemType="https://schema.org/AggregateRating">
          <meta itemProp="ratingValue"
                content={siteMeta.publisher.aggregateRating.ratingValue}
          />
          <meta itemProp="bestRating"
                content={siteMeta.publisher.aggregateRating.bestRating}
          />
          <meta itemProp="reviewCount"
                content={siteMeta.publisher.aggregateRating.reviewCount}
          />
        </div>
        <div itemProp="review" itemScope itemType="https://schema.org/Review">
          <div itemProp="author" itemScope itemType="http://schema.org/Person">
            <meta itemProp="name"
                  content={siteMeta.publisher.review.author.name}
            />
          </div>
          <div itemProp="reviewRating" itemScope itemType="http://schema.org/Rating">
            <meta itemProp="ratingValue"
                  content={siteMeta.publisher.review.ratingValue}
            />
            <meta itemProp="bestRating"
                  content={siteMeta.publisher.review.bestRating}
            />
          </div>
        </div>
      </header>
    )
  }
}

export default (props) => {
  const data = useStaticQuery(graphql`
    query HeaderComponentQuery {
      site {
        siteMetadata {
          title
          description
          publisher {
            areaServed
            alternateName
            foundingDate
            sameAs
            contactPoint {
              contactType
              email
            }
            address {
              streetAddress
              addressLocality
              country
              zip
            }
            emails {
              contact
            }
            logo
            name
            phone
            url
            person {
              name
              gender
              jobTitle
              sameAs
            },
            aggregateRating {
              ratingValue
              bestRating
              reviewCount
            }
            review {
              author {
                name
              }
              ratingValue
              bestRating
            }
          }
        }
      }
    }
  `)
  return (
    <Header  {...props} data={data}/>
  )
};