import React from 'react'
import Helmet from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import get from 'lodash/get'
import Header from "../components/header/header";
import Footer  from './footer'
import WOW from 'wowjs';
import base from '../scss/base.scss'
import sales from '../scss/sales.scss'
import hover from '../scss/hover.scss'
import animate from '../scss/animate.scss'
import StickyBar from "./stiky-bar/sticky-bar";
import BackToTop from "./back-to-top/back-to-top";
import SEO from "../components/seo.js";
import Breadcrumbs from './breadcrumbs/breadcrumbs';

class Template extends React.Component {
  constructor(props) {
    super(props);
    this.state = { reactLoaded: false }
    this.seo = {
      // Remove this for prod - only for dev
      disallowIndexing: true,
    }
  }
  componentDidMount() {
    this.setState({
      reactLoaded: true
    })
    new WOW.WOW().init();
  }
  render() {
    const siteMeta = get(this, 'props.data.site.siteMetadata')
    const { location, children, hiddenFooter, hiddenSticky, hiddenHeader } = this.props
    return (
      <>
        <Helmet>
          <body className={!this.state.reactLoaded ? 'react-cloak' : '' } />
          <link rel="preconnect" href="//www.google-analytics.com" />
        </Helmet>
        <SEO { ...this.seo }/>
        {hiddenSticky ? '' : <StickyBar/>}
        {hiddenHeader ? '' : <BackToTop />}
        <Header hiddenHeader={hiddenHeader} />
        <div className={`${this.props.containerClassNames || ''}`}>
          { this.props.breadcrumbs && <Breadcrumbs items={ this.props.breadcrumbs } location={ location } /> }
          {children}
        </div>
        {hiddenFooter ? '' : <Footer trustPilotEnabled={ !this.props.preventTrustPilot } ctaEnabled={false}/>}
      </>
    )
  }
}

export default (props) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title,
          description,
          description,
          seoTitle,
          idGTM
        }
      }
    }
  `)
  return (
    <Template  {...props} data={data}/>
  )
};
