import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import { truncate } from "lodash"
import styles from "./breadcrumbs.scss"
import { extractBreadcrumbsStructuredDataJsonFromLocation } from "../../helpers"

export default (props) => {
  const { items, className, location } = props
  return (
    <>
      <Helmet>
        <script type='application/ld+json'>
          { JSON.stringify(extractBreadcrumbsStructuredDataJsonFromLocation(items)) }
        </script>
      </Helmet>
      <div className={`breadcrumbs-container ${className || ''}`}>
        <nav className='container'>
          <ul className='breadcrumbs'>
            <li>
              <Link to='/'>Home</Link>
            </li>
            {
              items.map(({name, path}, index) => {
                const tName = items[index+1] ? truncate(name, {length: 45}) : truncate(name, {length: 35})
                return name && path ? (
                  <li key={ index }>
                    <Link to={path.replace(location.origin, '')}>
                      <span className="d-none d-md-inline">{ name }</span>
                      <span className="d-md-none">{ tName }</span>
                    </Link>
                  </li>) : ''
              })
            }
          </ul>
        </nav>
      </div>
    </>
  )
}
