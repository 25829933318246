import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import styles from './section-menu.scss';
import {graphql, Link, useStaticQuery} from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import get from 'lodash/get'
import { isExternalUrl } from "../../../src/helpers.js"


class SectionMenu extends React.Component {

  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      open: false
    }
    this.toggleMenu = this.toggleMenu.bind(this)
  }

  componentDidMount() {
    if(document.getElementsByClassName('navbar-toggler-icon')[0]){document.getElementsByClassName('navbar-toggler-icon')[0].addEventListener('mouseup', this.toggleMenu);}
    window.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    if(document.getElementsByClassName('close')[0]){document.getElementsByClassName('close')[0].removeEventListener('mouseup', this.toggleMenu);}
    window.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  toggleMenu() {
    this.setState({open: !this.state.open})
    if(this.state.open) {
      document.getElementsByClassName('navbar-toggler-icon')[0].classList.replace('navbar-toggler-icon','close')
    } else {
      document.getElementsByClassName('close')[0].classList.replace('close', 'navbar-toggler-icon')
    }
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (document.getElementById('navbarNav') && document.getElementById('navbarNav').classList.contains('show')) {
        document.getElementsByClassName('navbar-toggler')[0].click()
        this.toggleMenu();
      }
    }
  }

  render() {

    const { data, hiddenHeader } = this.props
    const ctaUrl = `${get(data, 'site.siteMetadata.certapetInfo.url.ctaUrl')}`
    /**
     * Extracts attribute from list of potential attributes
     * @param  {ContentfulMenuItem} menuItem
     * @param  {string} attribute's name
     * @param  {string} defaultItemValue
     * @param  {boolean} appendDefault
     * @return {string}
     */
    const extractValueFromAttributes = (menuItem, attribute, defaultItemValue, appendDefault) => {
      let attributeValue = defaultItemValue || ``
      if (menuItem.extraAttributes) {
        let extraAttributes = JSON.parse(get(menuItem, 'extraAttributes.internal.content', `{}`))
        if (attribute in extraAttributes) {
          attributeValue = appendDefault ? `${attributeValue} ${extraAttributes[attribute]}` : extraAttributes[attribute]
        }
      }

      return attributeValue
    }

    /**
     * Generates markup for single items
     * @param  {ContentfulMenuItem} menuItem
     * @return {HTMLDomElement}
     */
    const singleItemRenderer = (menuItem) => {
      let className = extractValueFromAttributes(menuItem, `className`, `nav-item text-uppercase`, true)
      return (
        // Because Nav.Link component doesn render nested component li > a
        <li className="nav-item" key={menuItem.id}>
          {
            isExternalUrl(menuItem.url)
              ? (<Nav.Link href={ menuItem.url } className={className} key={menuItem.id} as="a">{ menuItem.label }</Nav.Link>)
              : (<Link to={ menuItem.url } className={className} key={menuItem.id}>{ menuItem.label }</Link>)
          }
        </li>
      )
    }

    /**
     * Generates markup for dropdown items
     * @param  {ContentfulMenuItem} menuItem
     * @return {HTMLDomElement}
     */
    const dropdownItemRenderer = (menuItem) => {
      let className = extractValueFromAttributes(menuItem, `className`, `nav-item dropdown`, true)

      return (
        <NavDropdown title={menuItem.label} id="navbarDropdown" key={menuItem.id}>
          {menuItem.contentfulchildren.map((innerMenuItem, i) => {
            let className = extractValueFromAttributes(innerMenuItem, `className`, `dropdown-item`, false)
            if (isExternalUrl(innerMenuItem.url)) {
              return (<NavDropdown.Item className={className} href={innerMenuItem.url} key={i}>{innerMenuItem.label}</NavDropdown.Item>)
            } else {
              return (<Link to={ innerMenuItem.url } className={className} key={i}>{ innerMenuItem.label }</Link>)
            }
          })}
        </NavDropdown>
      )
    }
    const menuId = extractValueFromAttributes(data.contentfulMenu, `id`, `main-menu-therapetic-v2`)

    return(
      <>
        <div className="contain-menu my-md-2" ref={this.setWrapperRef}>
          <Navbar expand="lg" id={ menuId }>
            <Navbar.Brand href="/">
              <Img fixed={data.menuLogo.childImageSharp.fixed} objectFit="contain" alt="Therapetic Logo" className="img-fluid logo mt-3" />
            </Navbar.Brand>

            {hiddenHeader ? '' :
              <>
                <Navbar.Toggle />
                <Navbar.Collapse id="navbarNav" >
                  <Nav className="ml-auto" as="ul">
                    {data.contentfulMenu.menuItems.map((menuItem, i) => {
                      const isSingle = !menuItem.contentfulchildren
                      return isSingle ? singleItemRenderer(menuItem) : dropdownItemRenderer(menuItem);
                    })}
                    <li className="nav-item">
                      <div className="col-8 offset-3 d-sm-none mt-1">
                        <div className="cta-btn-screening-v2 text-center">
                          {/*<a href={`${ctaUrl}&utm_campaign=menuv2&utm_content=cta1`} target="_blank">GET STARTED</a>*/}
                          <a href="/get-started">GET STARTED</a>
                        </div>
                      </div>
                    </li>
                  </Nav>
                </Navbar.Collapse>
                <div className="col-sm-3 cta-btn pr-0 d-none d-sm-block">
                <div className="cta-btn-screening-v2 text-center">
                {/*<a href={`${ctaUrl}&utm_campaign=menuv2&utm_content=cta1`} target="_blank">GET STARTED</a>*/}
                <a href="/get-started">GET STARTED</a>
                </div>
                </div>
              </>
            }

          </Navbar>
        </div>
      </>
    )
  }
}


export default (props) => {
  const data = useStaticQuery(graphql`
    query MenuQuery {
      site {
        siteMetadata {
          certapetInfo {
            url {
              ctaUrl
            }
          }
        }
       } 
      menuLogo: file(relativePath: { eq: "header-logo.png" }) {
        childImageSharp {
          fixed(width: 232, height: 55) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      contentfulMenu( name: {eq: "Main Menu"} ) {
        name
        extraAttributes {
          internal {
            content
          }
        }
        menuItems {
          id
          label
          url
          position
          extraAttributes {
            internal {
              content
            }
          }
          contentfulchildren {
            label
            position
            url
            extraAttributes {
              internal {
                content
              }
            }
          }
        }
      }
    }
  `)

  return (
    <SectionMenu  {...props} data={data} />
  )
};
