const sanitizeHtml = require("sanitize-html")
const { kebabCase} = require("lodash")
const {
  ALL_BLOG_CATEGORIES_LABEL
} = require('./constants')

module.exports.isExternalUrl = (url) => url.match(/^(https?)?:\/\//)

module.exports.contentfulMapToLocalized = (node, localization = 'en-US') => {
  let newNode = {}
  for (let field in node) {
    if (node[field]) {
        newNode[field] = node[field]['en-US']
    }
  }
  
  return newNode
}

module.exports.htmlFromText = (htmlText, allowedTags, allowedAttributes) => {
  // Default options can be seen at: https://www.npmjs.com/package/sanitize-html#what-are-the-default-options
  let options = {}
  if (allowedTags) {
    options.allowedTags = allowedTags
  }
  if (allowedAttributes) {
    options.allowedAttributes = allowedAttributes
  }
  return {
    __html: sanitizeHtml(htmlText, options)
  }
}

//Return height of any element by id
module.exports.getElementHeightById = (id) => {
  const targetElement = document.getElementById(id)
  if (! targetElement) return 0
  const elementHeight = targetElement.getBoundingClientRect().height
  return elementHeight;
}
//Return height of sticky bar
module.exports.getStickyBarHeight = () => {
  // return getElementHeightById('sticky-bar')
  const targetElement = document.getElementById('sticky-bar')
  if (! targetElement) return 0
  const elementHeight = targetElement.getBoundingClientRect().height
  return elementHeight;
}
// export {getStickyBarHeight, getElementHeightById}

/**
 * Creates array containing widths for setting Img sizes and srcset
 * @param  {Object} image
 * @param  {Number} [minWidth=300]
 * @param  {Number} [widthHop=200]
 * @param  {Number} [maxWidth=null]
 * @return {Array}
 */
module.exports.createWidthHopsForImage = (image, minWidth = 300, widthHop = 200, maxWidth = null) => {
  maxWidth = maxWidth ? maxWidth : image.width
  let currentWidth = minWidth
  let widths = [];
  do {
    widths.push(currentWidth)
    currentWidth += widthHop
  } while (currentWidth < maxWidth)

  widths.push(maxWidth)

  return widths
}

module.exports.createMarkup = (rawHtml) => {
  return {__html: rawHtml};
}
/**
 * Creates url structure for authors
 * @param  {Object} author
 * @param  {String} [prefix='']
 * @return {String}
 */
module.exports.authorUrl = (author, prefix = '') => `${prefix}/authors/${kebabCase(author.name)}/`

/**
 * Builds breadcrumb JSON schema from location
 * @param  {Array} breadcrumbs Expects array of objects structured in the following share: {name: '', path :''}
 * @return {Object}
 */
module.exports.extractBreadcrumbsStructuredDataJsonFromLocation = (breadcrumbs) => {

  if (!breadcrumbs) {
    return {}
  }
  let structuredJson = {
    '@context': 'https://schema.org/',
    '@type': 'BreadcrumbList',
    '@id': '#breadcrumbs',
    itemListElement: breadcrumbs.map((item, index) => {
      const position = index + 1
      return {
        '@type': "ListItem",
        '@id': `#breadcrumbs-${position}`,
        position: position,
        item: {
          '@id': item.path,
          name: item.name
        }
      }
    })
  }

  return structuredJson
}

/**
 * Builds a breadcrumbs' item {name: '', path: ''}
 * @param  { String } name
 * @param  { String } path
 * @return { Object }
 */
module.exports.buildBreadcrumbsItem = (name, path) => {
  return {
    name,
    path
  }
}

/**
 * Creates url structure for authors
 * @param  {Object} author
 * @param  {String} [prefix='']
 * @return {String}
 */
module.exports.authorUrl = (author, prefix = '') => `${prefix}/authors/${kebabCase(author.name)}/`

/**
 * Creates url structure for ESA Pages
 * @param esa
 * @param prefix
 * @returns {string}
 */
module.exports.esaUrl = (esa = null, prefix = '') => {
  let path = `${prefix}/emotional-support-animal/`
  if (esa) {
    path += `${kebabCase(esa)}/`
  }

  return path
}

/**
 * Creates blog's link
 * @param  {String} [category=null]
 * @param  {Number} [page=1]
 * @param  {String} [prefix='']
 * @return {String}
 */
const blogUrl = (category = null, page = 1, prefix = '') => {
  let path = `${prefix}/blog/`
  if (category && category !== ALL_BLOG_CATEGORIES_LABEL) {
    path += `${kebabCase(category)}/`
  }
  if (page && page > 1) {
    path += `${page}/`
  }

  return path
}
module.exports.blogUrl = blogUrl

/**
 * Creates url structure for posts
 * @param  {Object} post
 * @param  {String} prefix
 * @return {String}
 */
module.exports.postUrl = (post, prefix = '') => `${prefix}${blogUrl()}${post.slug}/`


